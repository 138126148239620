<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-20">
      <span class="a-fs-16 a-fw-700">离线卡管理</span>
      <div class="a-flex-rcc">
        <le-export-btn :page-param="pageParam"></le-export-btn>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form id="leSearch" @reset="handlerRest" @search="handlerSearch">
        <le-input
          v-model="pageParam.params.cardNo"
          type="number"
          label="卡号"
          placeholder="请输入卡号"
        />
      </le-search-form>
      <le-pagview v-if="pageParam" :page-param="pageParam" @setData="setTableData">
        <el-table
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
          style="width: 100%"
        >
          <el-table-column prop="cardNo" label="卡号"></el-table-column>
          <el-table-column label="当前余额">
            <template slot-scope="{ row }">
              <span v-if="row.balance < 0">-&yen;{{ util.numFormat(Math.abs(row.balance)) }}</span>
              <span v-else>&yen;{{ util.numFormat(row.balance) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="充电次数">
            <template slot-scope="{ row }">
              <span>{{ row.chargingNum }}次</span>
            </template>
          </el-table-column>

          <el-table-column label="是否已绑定用户">
            <template slot-scope="{ row }">
              <span>{{ row.status == 1 ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否启用">
            <template slot-scope="{ row }">
              <block v-if="row.status != 1">
                <div class="a-flex-rcc">
                  <span>{{ row.status == 0 ? "是" : "否" }}</span>
                  <le-switch
                    v-model="row.status"
                    :ac-value="0"
                    :inac-value="2"
                    @change="(e) => handlerStatusChange(row, e)"
                  ></le-switch>
                </div>
              </block>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="description" label="备注">
					</el-table-column> -->
        </el-table>
      </le-pagview>
    </el-card>
    <le-upload-file-img
      v-show="false"
      ref="pic"
      v-model="deviceXLS"
      :upload-url="$Config.apiUrl.offLineCardBatchImport"
      :accept-arr="['.xls', '.xlsx']"
      notnull
      label=""
      @handleSuccess="importDevSuccess"
    ></le-upload-file-img>
    <el-dialog title="新增卡片" :visible.sync="dialogAddCard" width="500px" class="addDialog">
      <el-form :model="form">
        <el-form-item label="卡号" :label-width="'112px'">
          <el-input v-model="form.cardNo" autocomplete="off" placeholder="请输入卡号"></el-input>
        </el-form-item>
        <el-form-item label="充值金额" :label-width="'112px'" class="a-mtb-24">
          <jd-input-price v-model="form.amount"></jd-input-price>
        </el-form-item>
        <!-- <el-form-item label="备注" :label-width="'112px'">
					<el-input type="textarea" v-model="form.description" autocomplete="off" placeholder="请输入备注" :maxlength="100"></el-input>
				</el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="resetForm">取消</el-button> -->
        <el-button type="primary" @click="handlerSubmit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导入失败清单" :visible.sync="dialogImportCard" width="30%" class="addDialog">
      <el-table :data="importResult" :highlight-current-row="true" style="width: 100%">
        <el-table-column prop="cardNo" label="卡号"></el-table-column>
        <el-table-column prop="errMsg" label="错误原因"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogImportCard = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import util from "../../utils/util";
import util2 from "../../utils/util.js";
export default {
  name: "ChargeCardOffLine",

  data() {
    return {
      util: util,
      dialogAddCard: false,
      tableData: [],
      pageParam: null,
      deviceXLS: "",
      fileUrl: "", //文件导出url
      form: {
        cardNo: "",
        amount: "",
        description: "",
      },
      importResult: [],
      dialogImportCard: false,
    };
  },
  created() {
    this.pageParam = {
      url: this.$Config.apiUrl.offLineCardList,
      method: "post",
      params: {
        exportFlag: false,
        cardNo: "",
        startTime: "",
        endTime: "",
      },
      freshCtrl: 1,
    };
  },
  methods: {
    handlerAddCard(command) {
      if (command == "a") {
        // 批量导入
        this.$refs["pic"].$refs["upload"].$refs["upload-inner"].handleClick();
      } else if (command == "b") {
        this.dialogAddCard = true;
      } else if (command == "c") {
        util2.download(
          "https://file.hichar.cn/pc/NFC%E7%A6%BB%E7%BA%BF%E5%8D%A1%E5%8D%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
        );
      }
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    setTableData(data, fileUrl) {
      this.tableData = data;
      this.fileUrl = fileUrl;
    },
    importDevSuccess(respData) {
      this.pageParam.freshCtrl++; // 重新请求表格数据
      if (respData && respData.length > 0) {
        this.dialogImportCard = true;
        this.importResult = respData;
        this.$message.error("导入完成");
      } else {
        this.$message.success("导入成功");
      }
    },
    handlerStatusChange(row, status) {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.offLineCardStatusUp,
          params: {
            cardNo: row.cardNo.trim(),
            status: status,
          },
        })
        .then(() => {
          this.pageParam.freshCtrl++;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    handlerRest() {
      this.pageParam.params = {
        exportFlag: false,
        cardNo: "",
      };
      this.dialogAddCard = false;
    },
    handlerSubmit() {
      if (!this.form.cardNo.trim()) {
        this.dialogAddCard = false;
        this.form.cardNo = "";
        this.form.amount = "";
        return false;
      }
      if (!this.form.cardNo.match(/^\d+$/)) {
        this.$message.error("卡号只能是数字");
        return;
      }
      if (this.form.amount === "") {
        this.$message.error("请输入金额");
        return;
      }
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.addOffLineCard,
          params: {
            cardNo: this.form.cardNo,
            amount: this.form.amount * 100,
            description: this.form.description,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.$notify({
              title: res.result.data,
              type: "success",
            });
            this.dialogAddCard = false;
            this.form.cardNo = "";
            this.form.amount = "";
            this.pageParam.freshCtrl++;
          } else {
            this.$message.error(res.result.message);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
  justify-content: flex-start;
  width: 72px !important;
}

.addDialog /deep/ .el-dialog__header {
  font-size: 16px;
  color: #303133;
  font-weight: bold;
  padding: 16px 24px;
}

.addDialog /deep/ .el-dialog__body {
  border-top: 1px solid #ebf0f5;
  border-bottom: 1px solid #ebf0f5;
  min-height: 136px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
}

.addDialog /deep/ .el-dialog__footer {
  padding-left: 24px;
  padding-right: 24px;
}

.addDialog /deep/ .el-form-item__label {
  text-align: left;
}
</style>
